.calendar-container {
    display: flex;
    width: 100%;
    min-height: 100vh;
    margin-top: 20px;
}

.calendar {
    flex: 3;
    display: flex;
    align-items: flex-start; /* Centrar verticalmente */
    justify-content: center; /* Centrar horizontalmente */
    padding: 0; /* Eliminar padding para minimizar márgenes */
}

.event-details-container {
    flex: 2;
    background-color: #f9f9f9;
    padding: 20px;
    overflow-y: auto;
    margin-top: 20px;
    width: 100%;
}

.react-calendar {
    width: 98%; /* Ajustado para dejar un margen mínimo */
    max-width: 98%;
    background-color: #fff;
    color: #222; /* Color del del día de la semana (Global) */
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.5em;
}

.react-calendar * {
    box-sizing: border-box;
}

.react-calendar__tile {
    position: relative;
    height: auto;
    font-size: 1em;
    background: none;
    color: #222; /* Color del número del día (Global) */
    padding: 5px;
    border: 1px solid #ddd; /* Color del Borde (Global) */
}

.react-calendar__tile abbr {
    position: absolute;
    top: 5px;
    left: 5px;
    font-weight: bold;
    font-size: 1.2em;
}

.event-list {
    display: flex;
    flex-direction: column;
    font-size: clamp(0.5rem, 1vw, 0.8rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
    width: 100%; 
}

.event-item {
    margin: 0;
    text-align: left;
    border: 1px solid #335ACF;
    border-radius: 5px;
    padding: 0;
    background-color: rgba(51, 90, 207, 0.1);
    font-size: 0.8em;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.event-item-multiple {
    height: 35px; /* Increase the height */
    display: flex;
    align-items: center;     /* Vertically center */
    justify-content: center; /* Horizontally center */
}

.event-item-multiple .event-time {
    font-size: 1.2em;     /* Increase font size */
    font-weight: bold;    /* Make text bold */
    text-align: center;   /* Center the text */
}

.event-time {
    font-weight: bold;
    color: #335ACF;
    font-size: 0.8em;
    overflow-wrap: break-word;
    padding: 2px 4px; 
}

.event-detail {
    display: flex;
    margin-bottom: 20px;
}

.event-detail img {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    border-radius: 8px;
}

.event-detail h3 {
    margin: 0;
    font-size: 1.2em;
}

.event-detail p {
    margin: 5px 0;
}

.event-indicator {
    width: 12px;
    height: 12px;
    background-color: rgb(229, 117, 107);
    border-radius: 50%;
    margin: 0 auto;
    margin-top: 5px;
}

.page-title {
    font-size: 3em;
    margin-bottom: 40px;
    text-align: center;
}

.react-calendar__navigation {
    text-align: center;
    
}

.react-calendar__navigation button {
    color: #0062A8;
    min-width: 60px;
    background: none;
    font-size: 20px;
    margin-top: 20px;
    font-weight: bold;
}

.react-calendar__navigation button:nth-child(1),
.react-calendar__navigation button:nth-child(5) {
    display: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}

abbr[title] {
    text-decoration: none;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #335ACF;
    border-radius: 6px;
}

.react-calendar__tile--now,
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: rgba(128, 128, 128, 0.3);
    border-radius: 6px;
    font-weight: bold;
    color: #222;
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: rgba(51, 90, 207, 0.4);
    color: white;
    border-radius: 6px;
}

.react-calendar__month-view__weekdays {
    text-align: center;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2em;
}

/* Media Queries para hacer el diseño responsivo */
@media (max-width: 1024px) {
    .calendar-container {
        flex-direction: column;
    }

    .calendar,
    .event-details-container {
        flex: 1;
        width: 100%;
    }

    .react-calendar {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .event-item {
        font-size: 0.7em;
    }

    .event-time {
        font-size: 0.7em;
    }

    .react-calendar {
        font-size: 0.9em;
    }

    .react-calendar__tile abbr {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .event-item {
        font-size: 0.6em;
    }

    .event-time {
        font-size: 0.6em;
    }

    .react-calendar {
        font-size: 0.8em;
    }

    .react-calendar__tile abbr {
        font-size: 0.9em;
    }
}
