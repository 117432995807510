.custom-calendar {
  width: 100%;
  aspect-ratio: 16 / 9; /* Calendario 16/9 */
}

.calendar-wrapper {
  width: 100%; /* Elimina cualquier restricción de tamaño aquí */
  margin: 0 auto; /* Centra el calendario */
}

.react-calendar {
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0 auto; /* Centra horizontalmente */
  justify-content: center;
  border: 1px solid #ddd; /* Añade un borde exterior al calendario */
  border-radius: 8px; /* Mantiene las esquinas redondeadas consistentes */
}

.react-calendar__month-view__weekdays,
.react-calendar__month-view__days,
.react-calendar__navigation {
  flex-shrink: 0;
}

.react-calendar__tile {
  height: auto;
  aspect-ratio: 1 / 1; 
  max-width: initial !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-calendar .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Ensure content is centered vertically */
  border: 1px solid #ddd; /* Add border to each day tile */
  box-sizing: border-box; /* Ensure borders are included in the element's total width and height */
}

.dashboard-calendar .react-calendar__tile abbr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.event-day {
  color: #0062A8; /* Cambia el color del número del día a rojo */
  font-weight: bold; /* Opción para hacer el número en negrita si lo deseas */
}

.event-indicator {
  width: 8px;
  height: 8px;
  background-color: #0062A8;
  border-radius: 50%;
  margin: auto;
}

.bg-white {
  background-color: #fff;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mt-1 {
  margin-top: 0.25rem;
}

.text-sm {
  font-size: 0.875rem;
}

.dashboard-calendar .react-calendar__navigation__label {
  font-size: 1.1em; 
}